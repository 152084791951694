<template>
  <b-container fluid>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('elearning_tim.other_selection')}}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <router-link to="other-trainee-trainer-registration-list" :class="'btn btn_add_new font-weight-bold'">{{ $t('elearning_tim.other_selection') }} {{ $t('globalTrans.list') }}</router-link>
                    <a v-if="formData.training_title_id" target="_blank" :href="trainingElearningServiceBaseUrl + '/storage/uploads/Other-Training-Import-Format.xlsx'" class="btn btn_add_new font-weight-bold">{{ $t('globalTrans.export_demo') }}</a>
                    <b-button v-if="formData.training_title_id" variant="primary btn_add_new" class="text-center" v-b-modal.modal-5>
                      {{  $t('globalTrans.excelImport') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form id="form" @submit.prevent="handleSubmit(addAll)" @reset.prevent="reset" >
                        <b-overlay :show="load">
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.fiscal_year_id"
                                      :options="fiscalYearList"
                                      id="fiscal_year_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider  v-if="$store.state.Auth.activeRoleId === 1" name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_config.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.office_type_id"
                                      :options="officeTypeList"
                                      id="office_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.office_id"
                                      :options="officeList"
                                      id="office_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.training_type_id"
                                    :options="trainingTypeList"
                                    id="training_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.training_category_id"
                                    :options="trainingCategoryList"
                                    id="training_category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.circular_memo_no"
                                      :options="circularList"
                                      id="circular_memo_no"
                                      :disabled="otherType"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Other Trainee Type" vid="other_trainee_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="other_trainee_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_tpm.trainee_type') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.other_trainee_type_id"
                                    :options="otherTraineeTypeList"
                                    :disabled="otherType"
                                    id="other_trainee_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                              <ValidationProvider name="Registration For" vid="registration_for" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="registration_for"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('elearning_tpm.registration_type') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.registration"
                                      :options="userTypeList"
                                      id="registration_for"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <b-row>
                            <div class="col-md-12">
                              <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.form')}}</legend>
                                <b-row class="m-3">
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                    <keep-alive>
                                      <ValidationProvider vid="'mobile'" name="Mobile"  rules="required|min:11|max:11">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="mobile"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          v-model="formData.detail.mobile"
                                          :placeholder="$t('globalTrans.mobile')"
                                          @blur="getMobileDataInfo()"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="error invalid-feedback" role="alert">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </keep-alive>
                                  </b-col>
                                </b-row>
                                <b-overlay :show="loads">
                                  <b-row class="m-3">
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="gender"
                                            slot-scope="{ valid, errors }"
                                        >
                                          <template v-slot:label>
                                            {{ $t('externalTraining.gender')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                              plain
                                              v-model="formData.detail.gender"
                                              :options="genderList"
                                              id="gender"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <ValidationProvider name="Name(En)" vid="name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                            {{ $t('globalTrans.name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-input
                                            rows="2"
                                            v-model="formData.detail.name"
                                            :placeholder="$t('globalTrans.name') + ' ' + $t('globalTrans.enn')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-form-input>
                                        <div class="error invalid-feedback" role="alert">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider vid="name_bn" name="Name (Bn)" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="name_bn"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.name_bn') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            rows="2"
                                            v-model="formData.detail.name_bn"
                                            :placeholder="$t('globalTrans.name_bn')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider vid="age" name="Age" rules="required">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="5"
                                              label-for="age"
                                              slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.age') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                              v-model="formData.detail.age"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider :vid="'name_of_father_husband'" name="Name of Father / Husband (En)">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="name_of_father_husband"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_tim.name_of_father_husband') }} {{ $t('globalTrans.enn') }}
                                            </template>
                                            <b-form-input
                                              rows="2"
                                              v-model="formData.detail.name_of_father_husband"
                                              :placeholder="$t('elearning_tim.name_of_father_husband') + ' ' + $t('globalTrans.enn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider :vid="'name_of_father_husband_bn'" name="Name of Father / Husband (Bn)">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            slot-scope="{ valid, errors }"
                                            label-for="name_of_father_husband_bn"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_tim.name_of_father_husband_bn') }}
                                            </template>
                                            <b-form-input
                                              rows="2"
                                              v-model="formData.detail.name_of_father_husband_bn"
                                              :placeholder="$t('elearning_tim.name_of_father_husband_bn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider :vid="'nid'" name="Nid">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            slot-scope="{ valid, errors }"
                                            label-for="nid"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.nid') }}
                                            </template>
                                            <b-form-input
                                              rows="2"
                                              v-model="formData.detail.nid"
                                              :placeholder="$t('globalTrans.nid')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider vid="other_org" name="Organization Name (En)">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="other_org"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.org_name') }} {{ $t('globalTrans.enn') }}
                                            </template>
                                            <b-form-input
                                            rows="2"
                                            v-model="formData.detail.other_org"
                                            :placeholder="$t('globalTrans.org_name') + ' ' + $t('globalTrans.enn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider vid="other_org_bn" name="Organization Name (Bn)" rules="">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="other_org_bn"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.org_name') }} {{ $t('globalTrans.bnn') }}
                                            </template>
                                            <b-form-input
                                            rows="2"
                                            v-model="formData.detail.other_org_bn"
                                            :placeholder="$t('globalTrans.org_name') + ' ' + $t('globalTrans.bnn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                          <ValidationProvider :vid="'designation'" name="Designation (En)" v-if="formData.registration === 1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="designation"
                                                  slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('globalTrans.designation') }} {{ $t('globalTrans.enn') }}
                                              </template>
                                              <b-form-input
                                                  rows="2"
                                                  v-model="formData.detail.designation"
                                                  :placeholder="$t('globalTrans.designation') + ' ' + $t('globalTrans.enn')"
                                              ></b-form-input>
                                              <div class="error invalid-feedback" role="alert">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                          <ValidationProvider :vid="'profession'" name="Profession (En)" v-else>
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="profession"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.profession') }} {{ $t('globalTrans.enn') }}
                                            </template>
                                            <b-form-input
                                                rows="2"
                                                v-model="formData.detail.designation"
                                                :placeholder="$t('globalTrans.profession') + ' ' + $t('globalTrans.enn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                          <ValidationProvider :vid="'designation_bn'" name="Designation (Bn)" v-if="formData.registration === 1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="designation_bn"
                                                  slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('globalTrans.designation') }} {{ $t('globalTrans.bnn') }}
                                              </template>
                                              <b-form-input
                                                  rows="2"
                                                  v-model="formData.detail.designation_bn"
                                                  :placeholder="$t('globalTrans.designation') + ' ' + $t('globalTrans.bnn')"
                                              ></b-form-input>
                                              <div class="error invalid-feedback" role="alert">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                          <ValidationProvider :vid="'profession_bn'" name="Profession (Bn)" v-else>
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="profession"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.profession') }} {{ $t('globalTrans.bnn') }}
                                            </template>
                                            <b-form-input
                                                rows="2"
                                                v-model="formData.detail.designation_bn"
                                                :placeholder="$t('globalTrans.profession') + ' ' + $t('globalTrans.bnn')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6" v-if="formData.registration === 2">
                                      <keep-alive>
                                        <ValidationProvider :vid="'farmer_card_number'" name="Farmer Card Number">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="farmer_card_number"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_tim.farmer_card_number') }}
                                            </template>
                                            <b-form-input
                                                rows="2"
                                                v-model="formData.detail.farmer_card_number"
                                                :placeholder="$t('elearning_tim.farmer_card_number')"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                          <ValidationProvider :vid="'address'" name="Address">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="address"
                                                  slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('elearning_accommodation.address') }}
                                              </template>
                                              <b-form-textarea
                                                  rows="2"
                                                  v-model="formData.detail.other_address"
                                                  :placeholder="$t('globalTrans.address')"
                                              ></b-form-textarea>
                                              <div class="error invalid-feedback" role="alert">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6"  v-if="formData.registration === 2">
                                      <keep-alive>
                                          <ValidationProvider :vid="'monthly_income'" name="Monthly Income">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="5"
                                                  label-for="monthly_income"
                                                  slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                  {{ $t('admission_form.monthly_income') }}
                                              </template>
                                              <b-form-input
                                                  rows="2"
                                                  v-model="formData.detail.monthly_income"
                                                  :placeholder="$t('admission_form.monthly_income')"
                                              ></b-form-input>
                                              <div class="error invalid-feedback" role="alert">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                    <b-col lg="4" md="4" sm="6" xs="6">
                                      <keep-alive>
                                        <ValidationProvider :vid="'educational_qualification'" name="Educational Qualification">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="educational_qualification"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.educational_qualification') }}
                                            </template>
                                            <b-form-textarea
                                                rows="2"
                                                v-model="formData.detail.name_of_highest_degree"
                                                :placeholder="$t('globalTrans.educational_qualification')"
                                            ></b-form-textarea>
                                            <div class="error invalid-feedback" role="alert">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </b-col>
                                  </b-row>
                                </b-overlay>
                              </fieldset>
                            </div>
                          </b-row>
                          <b-row>
                          <div class="col-md-12">
                            <b-button type="submit" variant="primary" class="btn btn-sm btn-primary float-right"><i class="fa fa-plus" aria-hidden="true"></i> {{ $t('globalTrans.add_more') }}</b-button>
                            <!-- <button
                              class="btn btn-sm btn-primary float-right"
                              @click="addAll"
                              type="button"
                            ><i class="fa fa-plus" aria-hidden="true"></i> {{ $t('globalTrans.add_more') }}
                            </button> -->
                          </div>
                          </b-row>
                          <b-row>
                            <div class="col-md-12">
                              <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.trainee')}} {{$t('globalTrans.list')}}</legend>
                                  <b-col md="12" class="table-responsive">
                                    <table class="table">
                                      <thead class="thead">
                                        <tr>
                                          <th style="width: 10%" class="text-center">{{$t('globalTrans.sl_no')}}</th>
                                          <th class="text-center" style="width: 30%">
                                            {{$t('globalTrans.name')}}
                                          </th>
                                          <th class="text-center" style="width: 20%">
                                            {{$t('admission_form.mobile_no')}}
                                          </th>
                                          <th class="text-center" style="width: 30%">{{$t('elearning_accommodation.address')}}</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tr v-for="(detail,index) in formData.all_details.filter(item => item.registration_for === 2)" :key="index">
                                        <td class="text-center">{{$n(index+1)}}</td>
                                        <td class="text-center">
                                          {{ $i18n.locale === 'bn' ? detail.name_bn : detail.name }}
                                        </td>
                                        <td class="text-center">
                                          {{detail.mobile}}
                                        </td>
                                        <td class="text-center">
                                          {{detail.other_address}}
                                        </td>
                                        <td class="text-center">
                                          <div @click="removeDetails(index, detail)" style="cursor:pointer">
                                            <i class="fas fa-window-close m-0 text-danger"></i>
                                          </div>
                                          <div @click="editDetails(detail)" style="cursor:pointer">
                                            <i class="ri-ball-pen-fill m-0"></i>
                                          </div>
                                        </td>
                                      </tr>
                                      <template v-if="formData.all_details.filter(item => item.registration_for === 2).length === 0">
                                        <tr>
                                          <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                        </tr>
                                      </template>
                                    </table>
                                  </b-col>
                              </fieldset>
                            </div>
                            <div class="col-md-12">
                              <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.trainer')}} {{$t('globalTrans.list')}}</legend>
                                  <b-col md="12" class="table-responsive">
                                    <table class="table">
                                      <thead class="thead">
                                        <tr>
                                          <th style="width: 10%" class="text-center">{{$t('globalTrans.sl_no')}}</th>
                                          <th class="text-center" style="width: 30%">
                                            {{$t('globalTrans.name')}}
                                          </th>
                                          <th class="text-center" style="width: 20%">
                                            {{$t('admission_form.mobile_no')}}
                                          </th>
                                          <th class="text-center" style="width: 30%">{{$t('elearning_accommodation.address')}}</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tr v-for="(detail,index) in formData.all_details.filter(item => item.registration_for === 1)" :key="index">
                                        <td class="text-center">{{$n(index+1)}}</td>
                                        <td class="text-center">
                                          {{ $i18n.locale === 'bn' ? detail.name_bn : detail.name }}
                                        </td>
                                        <td class="text-center">
                                          {{detail.mobile}}
                                        </td>
                                        <td class="text-center">
                                          {{detail.other_address}}
                                        </td>
                                        <td class="text-center">
                                          <div @click="removeDetails(index, detail)" style="cursor:pointer">
                                            <i class="fas fa-window-close m-0 text-danger"></i>
                                          </div>
                                          <div @click="editDetails(detail)" style="cursor:pointer">
                                            <i class="ri-ball-pen-fill m-0"></i>
                                          </div>
                                        </td>
                                      </tr>
                                      <template v-if="formData.all_details.filter(item => item.registration_for === 1).length === 0">
                                        <tr>
                                          <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                        </tr>
                                      </template>
                                    </table>
                                  </b-col>
                              </fieldset>
                            </div>
                          </b-row>
                        </b-overlay>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="button" variant="primary" class="mr-2" @click="createData">
                              {{ $t('globalTrans.save') }}
                            </b-button>
                              &nbsp;
                            <router-link to="other-trainee-trainer-registration-list" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                          </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
      <b-modal id="modal-5" size="lg" :title="$t('elearning_tim.other_selection')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <XlForm :formData="formData"/>
      </b-modal>
  </b-container>
</template>
<style scoped>
.hide {
visibility: hidden !important;
}
</style>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationAllLists, otherTrainingApplicationsStore, personalInfo, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import XlForm from './ExcelForm'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ValidationObserver,
    ValidationProvider,
     XlForm
  },
  data () {
    return {
      search: {
        org_id: 0,
        mobile: ''
      },
      formData: {
        check_time: '',
        circular_memo_no: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        other_trainee_type_id: 0,
        registration: 2,
        detail: {},
        all_details: []
      },
      trainingTypeList: [],
      otherTraineeTypeList: [],
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      batchList: [],
      allBatchListData: [],
      circularListData: [],
      otherType: false,
      load: false,
      loads: false,
      details_errors: [],
      circularList: [],
      circularLoading: false,
      circularErrorMsg: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerProfileError: ''
    }
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      this.formData.office_type_id = this.$store.state.Auth.authUser.office_type_id
      this.formData.office_id = this.$store.state.Auth.authUser.office_id
      this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
    }
  },
  computed: {
    genderList () {
      const genderList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
      ]
      return genderList
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    otherTraineeTList: function () {
      return this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.filter(item => item.status === 1)
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
        if (this.$store.state.Auth.activeRoleId === 1) {
          this.officeTypeList = this.getOfficeTypeList(newValue)
        }
      } else {
        this.trainingTypeList = []
      }
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'formData.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'formData.fiscal_year_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCircularList()
        // this.getSheduleList()
      }
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOtherTraineeData(newVal)
      }
    },
    'formData.other_trainee_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    // this.getCircularList()
    flatpickr('.fromDate', {})
  },
  methods: {
    getTypeList (orgId) {
      if (this.formData.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      }
    },
    getOtherTraineeData (id) {
      const batchData = this.allBatchListData.find(allBatchList => allBatchList.circular_memo_no === id)
      if (batchData) {
        const batchList = []
          if (batchData.other_trainee_type_id !== 'null') {
            const mapData = Array.isArray(batchData.other_trainee_type_id) ? batchData.other_trainee_type_id : JSON.parse(batchData.other_trainee_type_id)
            for (const key in mapData) {
              const obj = mapData[key]
              if (obj) {
                const isThere = this.otherTraineeTList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj))
                if (typeof isThere !== 'undefined') {
                  batchList.push(isThere)
                }
              }
            }
          this.otherTraineeTypeList = batchList
        } else {
          this.otherTraineeTypeList = []
        }
      }
    },
    circluarList (circularhData) {
      const tmpList = circularhData.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no }
        }
      })
      this.circularList = tmpList
      if (this.circularList.length === 1) {
        this.formData.circular_memo_no = 1
      }
    },
    async getCircularList () {
      if (this.formData.fiscal_year_id && this.formData.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          org_id: this.formData.org_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.circluarList(result.data)
        }
        this.circularLoading = false
      }
    },
    editDetails (data) {
      this.formData.detail = {
        editable: true,
        newSl: data.newSl,
        other_trainee_type_id: data.other_trainee_type_id,
        name: data.name,
        gender: data.gender,
        name_bn: data.name_bn,
        name_of_father_husband: data.name_of_father_husband,
        name_of_father_husband_bn: data.name_of_father_husband_bn,
        mobile: data.mobile,
        designation: data.designation,
        designation_bn: data.designation_bn,
        other_org: data.other_org_bn,
        other_org_bn: data.other_org_bn,
        farmer_card_number: data.farmer_card_number,
        other_address: data.other_address,
        age: data.age,
        monthly_income: data.monthly_income,
        nid: data.nid,
        name_of_highest_degree: data.name_of_highest_degree
      }
    },
    removeDetails (index, data) {
      // this.formData.all_details.splice(index, 1)
      const datas = []
      this.formData.all_details.map(detail => {
        if (detail.mobile !== data.mobile) {
          datas.push(detail)
        }
      })
      this.formData.all_details = datas
    },
    addAll () {
      if (this.formData.detail.name && this.formData.detail.name_bn && this.formData.detail.mobile && this.formData.detail.age && this.formData.detail.gender && this.formData.other_trainee_type_id) {
        if (this.formData.detail.editable) {
          const dataCheck = this.formData.all_details.map(details => {
            if (parseInt(details.newSl) === parseInt(this.formData.detail.newSl)) {
              this.formData.detail.registration_for = this.formData.registration
              this.formData.detail.other_trainee_type_id = this.formData.other_trainee_type_id
              return Object.assign({}, details, this.formData.detail)
            } else {
              return Object.assign({}, details)
            }
          })
          this.formData.all_details = dataCheck
          this.formData.detail = {}
          this.$refs.form.reset()
        } else {
          const isData = this.formData.all_details.find(details => parseInt(details.mobile) === parseInt(this.formData.detail.mobile))
          if (typeof isData === 'undefined') {
            const detailField = this.formData.detail
            let sl = 0
            if (this.formData.all_details.length > 0) {
              sl = this.formData.all_details.length
            }
            detailField.newSl = sl
            detailField.registration_for = this.formData.registration
            detailField.other_trainee_type_id = this.formData.other_trainee_type_id
            this.formData.all_details.push(detailField)
            this.formData.detail = {}
            this.$refs.form.reset()
          }
        }
        this.otherType = true
      }
    },
    async getMobileDataInfo () {
      if (this.formData.detail.mobile) {
        this.loads = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalInfo + this.formData.detail.mobile)
        if (!result.success) {
          this.formData.detail.name = null
          this.formData.detail.name_bn = null
          this.formData.detail.name_of_father_husband = null
          this.formData.detail.name_of_father_husband_bn = null
          this.formData.detail.designation = null
          this.formData.detail.designation_bn = null
          this.formData.detail.other_org = null
          this.formData.detail.other_org_bn = null
          this.formData.detail.farmer_card_number = null
          this.formData.detail.other_address = null
          this.formData.detail.age = null
          this.formData.detail.monthly_income = null
          this.formData.detail.nid = null
          this.formData.detail.name_of_highest_degree = null
          this.formData.detail.gender = null
          this.loads = false
        } else {
          const trData = result.data
          this.formData.detail.name = trData.name
          this.formData.detail.name_bn = trData.name_bn
          this.formData.detail.name_of_father_husband = trData.name_of_father_husband
          this.formData.detail.name_of_father_husband_bn = trData.name_of_father_husband_bn
          this.formData.detail.designation = trData.designation
          this.formData.detail.designation_bn = trData.designation_bn
          this.formData.detail.other_org = trData.other_org
          this.formData.detail.other_org_bn = trData.other_org_bn
          this.formData.detail.farmer_card_number = trData.farmer_card_number
          this.formData.detail.other_address = trData.other_address
          this.formData.detail.age = trData.age
          this.formData.detail.monthly_income = trData.monthly_income
          this.formData.detail.nid = trData.nid
          this.formData.detail.name_of_highest_degree = trData.name_of_highest_degree
          this.formData.detail.gender = trData.gender
          this.loads = false
        }
      }
    },
    async loadData () {
      this.load = true
      const data = this.formData
      data.details = []
      data.all_details = []
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationAllLists, data)
      if (result.success) {
        const datas = result.data.map((item, index) => {
          const desigData = {
            newSl: index
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const desigBn = {}
          desigBn.designation_bn = item.other_designation_bn
          return Object.assign({}, item, desigData, desigBn)
        })
        this.formData.all_details = datas
      } else {
        this.formData.all_details = []
      }
      this.load = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeTypeOfficerList (officeTypeId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return OfficeTypeList.filter(item => item.value === officeTypeId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    // getOtherTrainee (id) {
    //   if (id !== null) {
    //     const office = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.find(item => item.value === id)
    //     if (this.$i18n.locale === 'bn') {
    //       return office !== undefined ? office.text_bn : ''
    //     } else {
    //       return office !== undefined ? office.text_en : ''
    //     }
    //   } else {
    //     return ''
    //   }
    // },
    async createData () {
      this.load = true
      let result = null
      result = await RestApi.postData(trainingElearningServiceBaseUrl, otherTrainingApplicationsStore, this.formData)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.reset()
        this.$router.push('/training-e-learning-service/tpm/other-trainee-trainer-registration-list')
      }
      this.load = false
    }
},
beforeDestroy () {
  clearInterval(this.inputTimer)
}
}
</script>
