// Training Calendar api
export const trainingCalendarBaseUrl = '/tpm/training-calendar/'
export const trainingCalendarList = trainingCalendarBaseUrl + 'list'
export const trainingCalendarToggleStatus = trainingCalendarBaseUrl + 'toggle-status'
export const trainingCalendarStore = trainingCalendarBaseUrl + 'store'
export const trainingCalendarUpdate = trainingCalendarBaseUrl + 'update'
export const trainingCalendarShow = trainingCalendarBaseUrl + 'show'
export const circularPublication = trainingCalendarBaseUrl + 'circular-publication'
export const circularPublicationList = trainingCalendarBaseUrl + 'circular-publication-list'
export const circularPublicationCalender = trainingCalendarBaseUrl + 'circular-publication-calender'
export const trainingCalendarFinalSave = trainingCalendarBaseUrl + 'final-save'
export const trainingCalenderFiscalYearWise = trainingCalendarBaseUrl + 'training-calender-fiscal-year-wise'
export const trainingCalenderPdf = trainingCalendarBaseUrl + 'training-calender-pdf'
export const trainingCalenderWord = trainingCalendarBaseUrl + 'training-calender-word'

// Trainer Profile api
export const trainerProfileBaseUrl = '/tpm/trainer-profiles/'
export const trainerProfileList = trainerProfileBaseUrl + 'list'
export const trainerProfileStore = trainerProfileBaseUrl + 'store'
export const trainerProfileUpdate = trainerProfileBaseUrl + 'update'
export const trainerProfileShow = trainerProfileBaseUrl + 'show'
export const trainerProfileToggleStatus = trainerProfileBaseUrl + 'toggle-status'
export const trainerProfilePersonalInfo = trainerProfileBaseUrl + 'personal-info'

// Course Managment api
export const courseManagmentBaseUrl = '/tpm/course-management/'
export const courseManagmentList = courseManagmentBaseUrl + 'list'
export const courseManagmentToggleStatus = courseManagmentBaseUrl + 'toggle-status'
export const courseManagmentStore = courseManagmentBaseUrl + 'store'
export const courseManagmentUpdate = courseManagmentBaseUrl + 'update'
export const courseManagmentShow = courseManagmentBaseUrl + 'show'
export const courseManagmentFinalSave = courseManagmentBaseUrl + 'final-save'

// Trainer Sorting api
export const trainerSortingBaseUrl = '/tpm/trainer-sorting/'
export const trainerSortingList = trainerSortingBaseUrl + 'list'
export const trainerSortingStore = trainerSortingBaseUrl + 'store'

// Course Module api
export const courseModuleBaseUrl = '/tpm/course-module/'
export const courseModuleList = courseModuleBaseUrl + 'list'
export const traineeTrainerOtherList = '/iabm/training-applications/other-trainee-trainer-list'
// export const traineeTrainerOtherDetails = '/iabm/training-applications/tto-list'
export const traineeTrainerOtherDetails = '/tpm/training-attendence/list'
export const personalInfoAdmin = '/iabm/personal-infos/personal-admin/'
export const personalInfo = '/iabm/personal-infos/personal-all-data/'
export const trainingTitleModule = courseModuleBaseUrl + 'training-title-module'
export const courseModuleToggleStatus = courseModuleBaseUrl + 'toggle-status'
export const courseModuleStore = courseModuleBaseUrl + 'store'
export const courseModuleUpdate = courseModuleBaseUrl + 'update'
export const courseModuleShow = courseModuleBaseUrl + 'show'
export const courseWiseModule = courseModuleBaseUrl + 'course-wise-module'
export const courseModuleTrainer = '/tim/bill-payment/select-trainer'

// Trainer Selection api
export const trainerSelectionBaseUrl = '/tpm/trainer-selections/'
export const trainerSelectionList = trainerSelectionBaseUrl + 'list'
export const trainerSelectionStore = trainerSelectionBaseUrl + 'store'
export const courseModuleFinalSave = courseModuleBaseUrl + 'final-save'

// Course Schedule api
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingScheduleList = trainingScheduleBaseUrl + 'list'
export const otherTrainingScheduleList = trainingScheduleBaseUrl + 'other-schedule-list'
export const trainingScheduleRoutine = trainingScheduleBaseUrl + 'routine'
export const wordExportSchedule = trainingScheduleBaseUrl + 'word-export'
export const trainingScheduleCommittee = trainingScheduleBaseUrl + 'committee'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'circular-wise-trainer'
export const trainingScheduleTrainer = trainingScheduleBaseUrl + 'schedule-trainer'
export const trainingCheckTrainingInfo = trainingScheduleBaseUrl + 'check-training-info'
export const trainingScheduleToggleStatus = trainingScheduleBaseUrl + 'toggle-status'
export const trainingScheduleStore = trainingScheduleBaseUrl + 'store'
export const trainingScheduleUpdate = trainingScheduleBaseUrl + 'update'
export const trainingScheduleShow = trainingScheduleBaseUrl + 'show'
export const trainingScheduleFinalSave = trainingScheduleBaseUrl + 'final-save'
export const courseLessonData = trainingScheduleBaseUrl + 'course-lesson'
export const trainerDateData = trainingScheduleBaseUrl + 'trainer-date'
export const inviteTrainerData = trainingScheduleBaseUrl + 'invite-trainer-list'
export const scheduleCircularData = trainingScheduleBaseUrl + 'schedule-circular'

export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const trainingApplicationAllLists = trainingApplicationBaseUrl + 'all-application-list'
export const trainingApplicationList = trainingApplicationBaseUrl + 'application'
export const trainingApplicationListPaginate = '/iabm/booking/application'

export const attendenceSheetBaseUrl = '/tpm/training-attendence/'
export const attendenceSheetList = attendenceSheetBaseUrl + 'list'
export const trainingTitledata = attendenceSheetBaseUrl + 'training-information'

// Other Selection api
export const otherTrainingApplicationsBaseUrl = '/iabm/other-training-applications/'
export const otherTrainingApplicationsList = otherTrainingApplicationsBaseUrl + 'list'
export const otherTrainingApplicationsStore = otherTrainingApplicationsBaseUrl + 'store'
export const otherTrainingApplicationsExcelStore = otherTrainingApplicationsBaseUrl + 'excel-store'

// Vanue Management api
export const attendanceUploadsList = '/tpm/attendance-uploads/list'
export const attendanceUploadsStore = '/tpm/attendance-uploads/store'
export const attendanceUploadsUpdate = '/tpm/attendance-uploads/update'
export const attendanceUploadsToggleStatus = '/tpm/attendance-uploads/toggle-status'
export const attendanceUploadsDetails = '/tpm/attendance-uploads/detail'
export const attendanceUploadsDestroy = '/tpm/attendance-uploadsdestroy'

// Nothi api
export const nothi = 'nothi/'
export const nothiRoutinSentList = nothi + 'routine-sent-nothi'

// Filter Circular List
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'
export const circularPublicationBirtan = circularPublicationBaseUrl + 'circular-publication'

// other Training Calendar api
export const otherTrainingCalendarList = '/iabm/other-training-calendar/list'
export const otherTrainingCalendarStore = '/iabm/other-training-calendar/store'
export const otherTrainingCalendarUpdate = '/iabm/other-training-calendar/update'
export const otherTrainingCalendarDetails = '/iabm/other-training-calendar/show'

// driver Assign api
export const driverAssignList = '/tpm/driver-assign/' + 'list'
export const driverAssignStore = '/tpm/driver-assign/' + 'store'
export const driverAssignUpdate = '/tpm/driver-assign/' + 'update'

// Attendance api
export const attendanceBaseUrl = '/tim/attendances/'
export const attendanceAllList = attendanceBaseUrl + 'birtan-applicationList'

// other schedule api
export const otherTrainingScheduleBaseUrl = '/tpm/other-training-schedule/'
export const otherScheduleList = otherTrainingScheduleBaseUrl + 'list'
export const otherTrainingScheduleStore = otherTrainingScheduleBaseUrl + 'store'
export const otherTrainingScheduleRoutine = otherTrainingScheduleBaseUrl + 'routine'

// Hand note api
export const handNote = '/tpm/hand-note/'
export const trainerScheduleList = handNote + 'trainer-schedule-list'
export const trainerCourseList = handNote + 'trainer-course-list'
export const traineeCourseList = handNote + 'trainee-course-list'
export const handNoteStore = handNote + 'store'
export const handNoteShow = handNote + 'show'
export const handNoteCircularList = handNote + 'trainee-circular-list'

// Personal Info api
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const designationWiseUser = personalInfoBaseUrl + 'officer-list'
